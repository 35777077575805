html, body {
  color: $fontColor;
  background: $background;
  // background: linear-gradient(to right, #fcfcfc, #fefefe);
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;
  font-size: 14px;
  line-height: 1.6;
  font-size: calc( 14px + 0 * ( ( 100vw - 768px)/512 ) );
}
.brdr {
  border: 1px solid black;
  font-weight: 400;
  color: #745fb5;
}
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}
.page-title {
  margin-top: 2rem !important;
}
.card {
  border: 0;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-family: -apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;
    font-size: 17px;
    &.result-link {
      font-size: 27px;
      a {
        color: $fontColor;
        font-weight: 600;
      }
      .copy-result {
        color: $fontColor;
        margin: 12px 0 0 0;
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
        margin: 0; padding: 0;
      }
    }
  }
  .card-body {
    padding: 2rem;
  }
  &.hidden {
    display: none;
    visibility: hidden;
  }
  &.error {
    padding: 50px 20px;
    font-weight: 500;
    font-size: 22px;
  }
}

.form-file {
  width: 100%;
  .btn {
    font-size: 80%;
    border-radius: $radius;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
    color: white;

    &.btn-submit {
      background-color: $brandRed;
      &:hover {
        color: #ffaaaa;
      }
    }
    &.disabled {
      background-color: #aaa;
      &:hover {
        color: white;
      }
    }
  }

}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
  input, select {
    height: auto;
    overflow: hidden;
  }
  
  label {
    overflow: hidden;
  }
}

.form-label-group>select,
.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}
.form-label-group>select,
.form-label-group>label {
  -webkit-appearance:none;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.required-file {
  &.hidden {
    display: none;
    visibility: hidden;
  }
}
.file-feedback {
  background: darken($background, 1%);
  border: 1px dashed #888;
  border-radius: $radius;
  // font-family: $font-family-sans-serif;
  font-size: 100px;
  // font-weight: $subheadings-font-weight;
  color: rgba(0,0,0,0.4);
  text-align: center;
  &.hidden {
    display: none;
    visibility: hidden;
  }
}

.form__upload {
  margin-bottom: 8px;

  .upload_inner {
    background: darken($background, 1%);
    border: 1px dashed #888;
    border-radius: $radius;
    // font-family: $font-family-sans-serif;
    // font-size: $subheadings-font-size;
    // font-weight: $subheadings-font-weight;
    color: rgba(0,0,0,0.4);
    text-align: center;
    .upload_title {
      color: lighten($brandRed, 20%);
      // font-weight: $headings-font-weight;
      // font-size: $headings-font-size;
    }
    .upload_image {
      img {
        width: 20%;
        max-width: 80px;
      }
      padding: 10px 0;
    }
    .upload_text {
      margin: 12px;
      // font-weight: $subheadings-font-weight;
      // font-size: $subheadings-font-size;
    }

    &:focus {
      background: darken($background, 3%);
    }
    &:hover {
      background: darken($background, 3%);
      cursor: pointer;
    }
  }
}

.form-or {
  color: lighten($fontColor, 30%);
  vertical-align: middle;
  font-weight: 300;
  font-size: 12px;
  margin: 22px;

  &.light {
    font-weight: 300;
    color: lighten($fontColor, 40%);
  }
  a.autofill {
    margin: 12px 0 0 0;
    text-decoration: underline;
    cursor: pointer;
  }
}

.faq-row{
  margin: 40px 0;
}
.accordion {
  .card {
    border: 0;
    box-shadow: none;
    .card-header{
      background: none;
    }
  }
  h5 {
    .btn {
      color: $fontColor;
    }
  }
}

/* Fallbacks */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}